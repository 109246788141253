.messages {
  height: 100%;

  &List {
    height: calc(100% - 80px);
    overflow: scroll;
    position: relative;
    box-sizing: border-box;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &Form {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    padding: 0 1em .5em;
  }
}
