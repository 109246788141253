.messages {
  height: 100%;
  position: relative;
  &Accepted {
    outline: 2px dashed #e8e4e4;
  }
  &Rejected {
    outline: 2px dashed #ef7a7a;
  }
  &Hint {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    background-color: rgba(255, 255, 255, .7);
    z-index: 2;
  }
  &List {
    height: calc(100% - 80px);
    overflow: scroll;
    position: relative;
    box-sizing: border-box;
    border-radius: 6px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &Form {
    border-top: 1px solid #e8e4e4;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    padding: .5em 0em 0;
  }
}
