.warning {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &Wrapper {
    width: 50%;
    margin: 0 auto;
    background-color: #efbc7a;
    border-radius: 12px;
    padding: 12px;
  }
  &Icon {
    text-align: center;
    font-size: 30px;
  }
  &Title {
    font-weight: 700;
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
  }
  &Text {
    line-height: 22px;
    margin-bottom: 10px;
  }
  &Actions {
    text-align: center;
  }
}
