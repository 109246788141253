@import "src/styles/params";

.message {
  display: flex;
  flex-direction: column;

  &Item {
    display: flex;
    flex-direction: column;
    padding: .6em 1em;
    border-radius: 6px;
    position: relative;
    max-width: 70%;
    margin-bottom: 1em;
    &Name {
      font-weight: 700;
      font-size: 16px;
    }

    &Left {
      align-self: flex-start;
      background-color: $remoteChatMessageBg;
      color: #fff;
    }
    &Right {
      align-self: flex-end;
      background-color: $myChatMessageBg;
    }
    &Time {
      width: 100%;
      text-align: right;
      font-size: 10px;
      margin-top: 10px;
    }
  }
}
