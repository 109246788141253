.empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &Icon {
    font-size: 140px;
    line-height: 140px;
  }
  &Label {
    font-size: 38px;
    font-weight: 700;
    margin-top: .6em;
  }
  &Action {
    margin-top: .6em;
  }
}
