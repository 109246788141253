.notifications {
  &Filters {
    margin-top: 1em;
    margin-bottom: 3em;
    display: flex;
    justify-content: space-between;
  }
  &Item {
    margin-bottom: 2em;
    padding-bottom: 2em;
    border-bottom: 1px solid #e5dfdf;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}
