@import "src/styles/params";

.videos {
  height: 100vh;
  box-sizing: border-box;
  padding: 1em 2em;
  display: flex;
  flex-direction: column;
  gap: 8px;
  &Loader {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    background-color: rgba(255, 255, 255, .8);
    &Refresh {
      text-align: center;
      font-size: 14px;
      margin-top: 12px;
      z-index: 99;
    }
  }
  &Header {
    height: 40px;
  }
  &Container {
    height: calc(100% - 120px);
    position: relative;
    &Remote {
      width: 100%;
      background-color: #000;
      height: 100%;
      border-radius: 6px;
    }
    &Self {
      position: absolute;
      bottom: 1em;
      left: 1em;
      width: 260px;
      height: 160px;
      background-color: #fff;
      border-radius: 6px;
    }
  }

  &Chat {
    box-sizing: border-box;
    padding: 2em 0;
    height: 100vh;
  }
  &RemoteOffMessage {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    background-color: rgba(255, 255, 255, .8);
  }
}
