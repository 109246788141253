.controls {
  height: 80px;
  text-align: center;
  padding-top: 1em;
  &Button {
    margin: 0 8px;
    background-color: #fff;
    border-radius: 6px;
    border: none;
    box-shadow: 0 0 13px 0 #ccc;
    padding: .6em;
    font-size: 20px;
    cursor: pointer;
    position: relative;

  }
}
